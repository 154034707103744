import React from 'react';
import Helmet from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';
import Layout from '../components/layout';
import Banner from '../components/Banner';
import Gallery from '../components/Gallery';
import SinglePicture from '../components/SinglePicture';

import poster from '../assets/images/separateImages/Lipdukai_Vytis.jpg';

const BrandingStyle = () => (
  <Layout>
    <Helmet
      title="Velgama - Plačiaformatė spauda"
      meta={[
        { name: 'description', content: 'Tentai, paprasta ir perforuota plėvelė. Stiklų matinimas perforuota plėvele' },
      ]}
    />
    <div id="main">
      <Banner
        h1="Plačiaformatė spauda"
        paragraph="Tentai, paprasta ir perforuota plėvelė."
        paragraph2="STIKLŲ MATINIMAS ŠERKŠNO PLĖVELE"
        bannerType="wide-print-banner"
      />
      <section id="one" className="spotlights">
        <StaticQuery
          query={galleryQuery}
          render={(data) => <Gallery data={data} />}
        />
      </section>
      <SinglePicture src={poster} alt="Plačiaformatės spaudos plakatas su kainomis" />
    </div>
  </Layout>
);

const galleryQuery = graphql`
query widePrintImages {
    allFile(
      filter: { sourceInstanceName: { eq: "images-wide-print" } },
      sort: {order: ASC, fields: name}
      ) {
      edges {
        node {
          dir
          name
          childImageSharp{
            fluid (maxWidth: 2400, maxHeight: 1800){
                ...GatsbyImageSharpFluid
              }
          }
        }
      }
    }
  }
`;

export default BrandingStyle;
